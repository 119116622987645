.chart-container {
  max-width: 100%;
  height: 400px;
}

.App {
  box-sizing: border-box;
  margin: 0;
  background: white;
  height: 100vh;
  overflow-y: scroll;
}

.App-logo {
  height: 6vmin;
  pointer-events: none;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.app-bar-title {
  flex-grow: 1;
}

.main-container {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
}

.auth-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dashboard-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 250px;
}

/* 404 Design CSS */
@import url("https://fonts.googleapis.com/css?family=Merriweather+Sans") main
    height: 100vh width: 100vw background: #fff display: flex flex-direction:
    column justify-content: center align-items: center font-family:
    "Merriweather Sans",
  sans-serif #errorText font-size: 22px margin: 14px 0 #errorLink font-size:
    20px padding: 12px border: 1px solid color: #000 background-color:
    transparent text-decoration: none transition: all 0.5s ease-in-out &: hover,
  &: active color: #fff background: #000 #g6219 transform-origin: 85px 4px
    animation: an1 12s 0.5s infinite ease-out @keyframes an1 0% transform:
    rotate(0) 5% transform: rotate(3deg) 15% transform: rotate(-2.5deg) 25%
    transform: rotate(2deg) 35% transform: rotate(-1.5deg) 45% transform:
    rotate(1deg) 55% transform: rotate(-1.5deg) 65% transform: rotate(2deg) 75%
    transform: rotate(-2deg) 85% transform: rotate(2.5deg) 95% transform:
    rotate(-3deg) 100% transform: rotate(0);
